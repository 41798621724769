import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var uploadPicture = function uploadPicture() {
  return import("../common/uploadPicture");
};

export default {
  components: {
    uploadPicture: uploadPicture
  },
  data: function data() {
    return {
      pagination: {
        current: 1,
        size: 10,
        total: 0,
        resourceType: ""
      },
      resources: [],
      resourceDialog: false,
      storeTypes: [{
        label: "服务器",
        value: "local"
      }, {
        label: "七牛云",
        value: "qiniu"
      }],
      storeType: localStorage.getItem("defaultStoreType")
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getResources();
  },
  mounted: function mounted() {},
  methods: {
    handleDelete: function handleDelete(item) {
      var _this = this;

      this.$confirm('确认删除资源？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        center: true
      }).then(function () {
        _this.$http.post(_this.$constant.baseURL + "/resource/deleteResource", {
          path: item.path
        }, true, false).then(function (res) {
          _this.pagination.current = 1;

          _this.getResources();

          _this.$message({
            message: "删除成功！",
            type: "success"
          });
        }).catch(function (error) {
          _this.$message({
            message: error.message,
            type: "error"
          });
        });
      }).catch(function () {
        _this.$message({
          type: 'success',
          message: '已取消删除!'
        });
      });
    },
    addPicture: function addPicture(res) {},
    addResources: function addResources() {
      if (this.$common.isEmpty(this.pagination.resourceType)) {
        this.$message({
          message: "请选择资源类型！",
          type: "error"
        });
        return;
      }

      this.resourceDialog = true;
    },
    search: function search() {
      this.pagination.total = 0;
      this.pagination.current = 1;
      this.getResources();
    },
    getResources: function getResources() {
      var _this2 = this;

      this.$http.post(this.$constant.baseURL + "/resource/listResource", this.pagination, true).then(function (res) {
        if (!_this2.$common.isEmpty(res.data)) {
          _this2.resources = res.data.records;
          _this2.pagination.total = res.data.total;
        }
      }).catch(function (error) {
        _this2.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    changeStatus: function changeStatus(item) {
      var _this3 = this;

      this.$http.get(this.$constant.baseURL + "/resource/changeResourceStatus", {
        id: item.id,
        flag: item.status
      }, true).then(function (res) {
        _this3.$message({
          message: "修改成功！",
          type: "success"
        });
      }).catch(function (error) {
        _this3.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    handlePageChange: function handlePageChange(val) {
      this.pagination.current = val;
      this.getResources();
    }
  }
};